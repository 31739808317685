<template>
  <b-card title="Colors Table">
    <div class="mb-sm-0">
      <div class="d-flex align-items-center">
        <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            class="float-right"
            variant="primary"
            :to="{ name: 'create-color' }"
        >
          Add Color
        </b-button>
      </div>
      <ColorsTable/>
    </div>
    <!-- search input -->
  </b-card>
</template>

<script>
import {VueGoodTable} from "vue-good-table";
import {
  BAvatar,
  BBadge, BButton, BCard,
  BDropdown,
  BDropdownItem, BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BPagination
} from "bootstrap-vue";
import Prism from "vue-prism-component";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ColorsTable from "@/views/components/colors/ColorsTable";

export default {
  name: "Index",
  components: {
    ColorsTable,
    VueGoodTable, BAvatar, BBadge,
    BPagination, BFormGroup, BFormInput,
    BFormSelect, Prism, BDropdownItem,
    BDropdown, BCard, BButton,
    vSelect, BFormCheckbox
  },
  directives: {
    Ripple,
  },
}
</script>

<style scoped>

</style>
