<template>
  <div class="">
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input v-model="params.search" placeholder="Search" type="text" class="d-inline-block"
                        debounce="1000"/>
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <div class="">
      <div v-if="isLoading" class="overlay">
      </div>
      <vue-good-table mode="remote"
                      :pagination-options="{enabled: true}"
                      :totalRows="totalRecords"
                      :rows="rows" :columns="columns"
                      @on-page-change="onPageChange"
                      @on-sort-change="onSortChange"
                      @on-per-page-change="onPerPageChange"
                      :isLoading.sync="isLoading">
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'image'">
            <BImgLazy v-if="props.row.media[0]" class="color-sample"
                      :src="props.row.media[0].original_url"
                      alt="color sample"/>
          </span>
          <span v-else-if="props.column.field === 'status'">
            <b-form-checkbox @change="() => changeStatus(props.row.id)"
                             :checked="props.row.status === 'Active'"
                             switch inline> </b-form-checkbox>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
          <span v-if="props.column.field === 'action'">
             <b-button variant="secondary" :to="{ name: 'edit-color', params: {color_id: props.row.id}}">
              <feather-icon icon="SettingsIcon"/>
             </b-button>
          </span>
        </template>
        <template #emptystate>
          <p class="text-center">
            {{ isSorting ? "Loading..." : "There is no data to show!" }}
          </p>
        </template>
      </vue-good-table>
    </div>
  </div>
</template>

<script>
import {VueGoodTable} from "vue-good-table";
import Ripple from "vue-ripple-directive";
import ColorService from "@/services/ColorService";
import '@core/scss/vue/libs/vue-good-table.scss';
import InlineCellEdit from "@/views/components/shared/InlineCellEdit";

export default {
  name: "ColorsTable",
  components: {
    InlineCellEdit,
    VueGoodTable,
  },
  directives: {
    Ripple,
  },
  props: {
    showAll: {
      Type: Boolean,
      default: true,
    },
    propsParams: {
      Type: Object,
    }
  },
  data() {
    return {
      params: {
        page: undefined,
        per_page: undefined,
        sort_keys: undefined,
        sort_dir: undefined,
        search: undefined,
      },
      columns: [
        {label: 'Ref', field: 'id'},
        {label: 'Color Type', field: 'type'},
        {label: 'Color Code', field: 'code'},
        {label: 'Color Name', field: 'name'},
        {label: 'Color Sample', field: 'image', sortable: false},
        {label: 'Active', field: 'status', width: '100px'},
        {label: 'Edit', field: 'action', width: '100px', sortable: false},
      ],
      rows: [],
      isLoading: undefined,
      isSorting: true,
      totalRecords: undefined,
      ColorService: new ColorService(),
      modalOptions: undefined
    }
  },
  watch: {
    params: {
      handler: function () {
        this.rows = []
        this.getColors();
      },
      deep: true
    }
  },
  created() {
    this.getColors();
  },
  methods: {
    changeStatus(id) {
      this.isLoading = true;
      const value = !(this.rows.find(el => el.id === id).status === "Active");
      this.ColorService.changeStatus(id, value).then(res => {
        this.rows.find(el => el.id === id).status = value;
        this.getColors();
      }).catch(err => {
      })
          .finally(() => {
            this.isSorting = false;
          });
    },
    client(rowObj) {
      return `${rowObj.client.first_name} ${rowObj.client.last_name}`;
    },
    onPerPageChange(page) {
      this.params.page = page.currentPage;
      this.params.per_page = page.currentPerPage;
    },
    onPageChange(active) {
      this.params.page = active.currentPage;
      this.params.per_page = active.currentPerPage;
    },
    onSortChange(params) {
      const SortKey = typeof params[0].field === 'function' ? "client" : params[0].field;
      this.params.sort_keys = [SortKey];
      this.params.sort_dir = [params[0].type];
    },
    getColors() {
      this.isSorting = true;
      this.ColorService.index({...this.params, ...this.propsParams}).then(res => {
        this.rows = res.data;
        this.totalRecords = res.total;
      }).catch(err => {
      })
          .finally(() => {
            this.isSorting = false;
          });
    },
    openOfferHistoryModel(offer) {
      this.$refs.previewOffer.$refs.OfferHistory.openModel(offer);
    }
  },
}
</script>

<style scoped>
.color-sample {
  height: 38px;
  border-radius: 6px;
  width: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
}
</style>
